export default class CompanionsDashboardForm {
  constructor() {
    this.bindEvents();
  }


  bindEvents() {
    $("#s2").select2({});

    $("#has-card").prop('checked', false);
    $("#has-app").prop('checked', false);
    $("#app-help").prop('checked', false);
    $("#already-helped").prop('checked', false);
    $("#recommandation").prop('checked', true);

    $("#has-app").on('change', function() {
      if ($(this).is(':checked')) {
          $(".app_fields").hide();
      } else {
          $(".app_fields").show();
      }
    });

    $(".multiple").select2({
      tags: false,
      tokenSeparators: [',', ' '],
      placeholder: "Choisir",
    });

    $(".tags").select2({
      tags: true,
      tokenSeparators: [',', ' '],
      placeholder: "Nom des magasins visités",
      "language": {
        "noResults": function(){ return "Saisissez le nom du magasin visité" }
      }
    });

    this.dropify = $('#student_and_senior_photo').dropify({
      messages: {
          'default': "",
          'replace': I18n.t('plugins.dropify.messages.replace'),
          'remove':  I18n.t('plugins.dropify.messages.remove'),
          'error':   I18n.t('plugins.dropify.messages.error')
      },
      error: {
          'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
          'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
          'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
          'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
          'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
          'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
          'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
      }
    });

    $("#products_found_easily-yes").prop('checked', true);

    $(".pfe-radio").on('click', function() {
      var selectedOption = $(this).val();
      if (selectedOption === "false") {
        $('#product_retrieval_convenience').removeClass('d-none');
        $('#product_retrieval_convenience').find('input').attr('required', true);
      }
      else if (selectedOption === "true") {
        $('#product_retrieval_convenience').addClass('d-none');
        $('#product_retrieval_convenience').find('input').attr('required', false);
      }
    });

    $(".ah-radio").on('click', function() {
      var selectedOption = $(this).val();
      if (selectedOption === "false") {
        $('#no_app_help_reason').removeClass('d-none');
        $('#no_app_help_reason').find('input').attr('required', true);
      }
      else if (selectedOption === "true") {
        $('#no_app_help_reason').addClass('d-none');
        $('#no_app_help_reason').find('input').attr('required', false);
      }
    });

    $("#submit-btn").on('click', function(event) {
      event.preventDefault();

      // Vérifier les champs obligatoires
      var form = $("#task-form")[0];
      var inputs = $('.required-input');
      var all_inputs = $('input[required], textarea[required]');
      var invalid_feedbacks = $('.invalid-feedback');

      var isValid = true;

      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].classList.contains('check-input')) {
          // check among the sons radio or check buttons
          var sons = $(inputs[i]).find('input');
          var isSonValid = false;
          for (var j = 0; j < sons.length; j++) {
            if (sons[j].checked) {
              isSonValid = true;
              break;
            }
          }

          if (!isSonValid) {
            $(inputs[i]).addClass('is-invalid');
            if (invalid_feedbacks[i]) {
              invalid_feedbacks[i].classList.add('d-block');
            }
          } else {
            $(inputs[i]).removeClass('is-invalid');
            if (invalid_feedbacks[i]) {
              invalid_feedbacks[i].classList.remove('d-block');
            }
          }
        } else {
          if (!inputs[i].value) {
            $(inputs[i]).addClass('is-invalid');
            if (invalid_feedbacks[i]) {
              invalid_feedbacks[i].classList.add('d-block');
            }
          } else {
            $(inputs[i]).removeClass('is-invalid');
            if (invalid_feedbacks[i]) {
              invalid_feedbacks[i].classList.remove('d-block');
            }
          }
        }
      }

      for (var i = 0; i < all_inputs.length; i++) {
        if (!all_inputs[i].value) {
          isValid = false;
        }
      }

      var highlight_checkbox = $('.highlight-checkbox');
      var highlight_checkbox_inputs = $(highlight_checkbox);
      var isHighlightCheckboxValid = false;
      for (var i = 0; i < highlight_checkbox_inputs.length; i++) {
        if (highlight_checkbox_inputs[i].checked) {
          isHighlightCheckboxValid = true;
          break;
        }
      }

      if (!isHighlightCheckboxValid) {
        isValid = false;
      }

      if (isValid) {
        form.submit();
      }else {
        var first_invalid_input = $('.invalid-feedback.d-block')[0];
        if (first_invalid_input) {
          var scrollPosition = $(first_invalid_input).offset().top - 170;
          $('html, body').animate({ scrollTop: scrollPosition }, 500);
        }
      }
    });
  }
  
  onDataChanged(data) {
    
  }

  onDestroy() {
    this.dropify.data('dropify').destroy();
    $("#s2").select2('destroy');
    $(".multiple").select2('destroy');
  }
}
