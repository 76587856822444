export default class DistributorIndex {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.dataTable = $('.distributors-table').DataTable({
      "info": false,
      "lengthChange": false,
      retrieve: true,
      "searching": false,
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      dom: 'Bfrtip',
      paging: false,
      buttons: [],
      "columnDefs": [
        {
          "targets": [3],
          "orderable": false,
        }]
    });
  }

  onDataChanged(data) {
    
  }

  onDestroy() {
   if (this.dataTable) {
     this.dataTable.destroy();
      this.dataTable = null;
   }
  }
}