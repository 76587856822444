export default class BackofficeInvoicesIndex {

  constructor() {
    this.bindEvents();
  }


  bindEvents() {
    this.dataTable = $('.invoices-datatable').DataTable({
      "info": false,
      retrieve: true,
      "lengthChange": false,
      "searching": false,
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      dom: 'Bfrtip',
      paging: true,
      pageLength: 50,
      buttons: [],
    });

    $(".quarter-download").on('click', (e) => {
      var url = $(e.target).data('url');
      
      // download the file from the url
      window.open(url, '_blank').focus();

      return false;
    });
  }

  onDataChanged(data) {
    
  }

  onDestroy() {
   if (this.dataTable) {
     this.dataTable.destroy();
      this.dataTable = null;
   }
  }
}