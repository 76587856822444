import ApexCharts from 'apexcharts';

export default class StoreAdminDashboardAppInfos {
  
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_loyalty_chart();
    this.display_app_chart();
    this.display_app_help_chart();
    this.display_no_app_reason_chart();
  }

  display_loyalty_chart() {
    var data = $("#loyalty_chart").data("loyalty");

    var options = {
        series: [data, 100 - data],
        chart: {
          type: 'pie',
          offsetY: 20,
          height: 300,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: ['Oui', 'Non'],
      colors: ["#70a7dc", "#1f3864"]
    };

    var chart = new ApexCharts(document.querySelector("#loyalty_chart"), options);
    chart.render();
  }

  display_app_chart() {
    var data = $("#app_chart").data("app");

    var options = {
        series: [data, 100 - data],
        chart: {
          type: 'pie',
          offsetY: 20,
          height: 300,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: ['Oui', 'Non'],
      colors: ["#70a7dc", "#1f3864"],
    };

    var chart = new ApexCharts(document.querySelector("#app_chart"), options);
    chart.render();
  }

  display_app_help_chart() {
    var data = $("#app_help_chart").data("help");

    var options = {
        series: [data, 100 - data],
        chart: {
          type: 'pie',
          offsetY: 20,
          height: 300,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: ['Oui', 'Non'],
      colors: ["#70a7dc", "#1f3864"],
    };

    var chart = new ApexCharts(document.querySelector("#app_help_chart"), options);
    chart.render();
  }

  display_no_app_reason_chart() {
    var data = $("#chart_reason").data("counts");
    var labels = $("#chart_reason").data("labels");

    var options = {
        series: [{
        data: data,
      }],
        chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          barHeight: '60%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: labels,
        labels: {
          show: false
        },
        legend: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        legend: {
          show: false
        }
      },
      colors: ["#70a7dc"],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff'],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
      },
    };

    var chart = new ApexCharts(document.querySelector("#chart_reason"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $("#loyalty_chart").empty();
    $("#app_chart").empty();
  }
}
