import { Calendar } from 'fullcalendar';

export default class CompanionsDashboardCalendar {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    var self = this;
    this.calendar = new Calendar(document.getElementById('calendar'), {
      locale: 'fr',
      events: '/events-data/',
      firstDay: 1,
      dayMaxEventRows: 4,
      height: 400,
      allDaySlot: false,
      slotMinTime: "06:00:00",
      slotMaxTime: "21:00:00",
      eventDidMount: function(info) {
         if (info.event.extendedProps.is_outdated){
          info.el.style.backgroundColor = '#D3D3D3';
        } else {
          if (info.event.extendedProps.already_registered){
            info.el.style.backgroundColor = '#59B99F';
          }else{
            if (info.event.extendedProps.is_full){
              info.el.style.backgroundColor = '#edbb5f';
            } else{
              info.el.style.backgroundColor = '#6ACAE3';
            }
          }
        }
      },
      eventContent: function( info ) {
        let el = document.createElement('div')
        el.innerHTML = info.event.title;
        el.classList.add('fc-event-title');
        if (info.event.extendedProps.is_outdated){
          el.style.backgroundColor = '#D3D3D3';
        } else {
          if (info.event.extendedProps.is_late_withdrawal){
            el.style.backgroundColor = '#F5B041';
          }
          else if (info.event.extendedProps.nb_companions == info.event.extendedProps.nb_companions_max){
            el.style.backgroundColor = '#59B99F';
          } else if (info.event.extendedProps.nb_companions < info.event.extendedProps.nb_companions_max){
            el.style.backgroundColor = '#6ACAE3';
          }
        }
        let arrayOfDomNodes = [ el ]
        return { domNodes: arrayOfDomNodes }
      },
      dateClick: (info) => {
        self.calendar.gotoDate(info.dateStr);
        self.calendar.changeView('timeGridDay');
        $('.fc-Retour-button').removeClass("d-none")
      },
      eventClick: (info) => {
        if (info.view.type == "timeGridDay"){
          if (info.event.extendedProps.is_outdated){
            $("#expiredEventModal").modal("show");
            $("#expired-event-title").html(info.event.title);
          } else {
            if (info.event.extendedProps.already_registered){
              $("#alreadyRegisteredModalModal").modal("show");
              $("#already-registered-event-title").html(info.event.title);
            }else{
              if (info.event.extendedProps.is_full){
                $("#fullEventModal").modal("show");
                $("#full-event-title").html(info.event.title);
              }else{
                $("#eventRegistrationModal").modal("show");
                $("#submit-event-registration-btn").attr("href", "/event-register/" + info.event.id)
                $("#event-title").html(info.event.title);
              }
            }
          }
        }else{
          self.calendar.gotoDate(info.event.start);
          self.calendar.changeView('timeGridDay');
          $('.fc-Retour-button').removeClass("d-none")
        }
      },
      headerToolbar: {
        start: "prev,next",
        center: "title",
        end: "Retour"
      },
      customButtons: {
        Retour: {
          text: "Retour",
          click: function() {
            self.calendar.changeView('dayGridMonth');
            $('.fc-Retour-button').addClass("d-none")
          }
        }
      },
      viewDidMount: function(e) {
        var current_view = e.view.type;

        if (current_view == "timeGridDay"){
          $("#back-to-month-view-btn").removeClass("d-none");
        }else{
          $("#back-to-month-view-btn").addClass("d-none");
        }
      }
    });

    this.calendar.render();

    $(".fc-dayGridMonth-button").text("Mois");
    $(".fc-timeGridWeek-button").text("Semaine");
    $(".fc-timeGridDay-button").text("Jour");
    
    $(".fc-next-button, .fc-prev-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button").on('click', (event) => {
      $(".fc-dayGridMonth-button").text("Mois");
      $(".fc-timeGridWeek-button").text("Semaine");
      $(".fc-timeGridDay-button").text("Jour");
    });

    $(".fc-Retour-button").addClass("d-none");
    $("#back-to-month-view-btn").on('click', (event) => {
      self.calendar.changeView('dayGridMonth');
    });

    // filter select
    $("#store-select").select2({
      placeholder: "Choisir un magasin",
      allowClear: true
    });
    $(".select2-selection__clear").addClass('d-none');


    $("#store-select").on("change", function () {
      const storeID = $(this).val();

      self.calendar.getEvents().forEach((event) => {
        if (event.extendedProps.store_id == storeID) {
          event.setProp("display", "block");
        } else {
          event.setProp("display", "none");
        }
        $(".select2-selection__clear").addClass('d-none');
      });
    });

    var $dropdownTitle = $("#dropdown-title");

    $("#type-all, #type-registered, #type-available, #type-full").on('click', function(event) {
      var type = event.target.id.split("-")[1];

      switch (type) {
        case "all":
          $dropdownTitle.html("Tous");
          break;
        case "registered":
          $dropdownTitle.html("<span class='text-success pr-1'>●</span> Mes participations");
          break;
        case "available":
          $dropdownTitle.html("<span class='text-info pr-1'>●</span> Places disponibles");
          break;
        case "full":
          $dropdownTitle.html("<span class='text-warning pr-1'>●</span> Complets");
          break;
      }

      self.calendar.getEvents().forEach(function(event) {
        var isRegistered = event.extendedProps.already_registered || event.extendedProps.is_outdated;
        var isAvailable = !event.extendedProps.already_registered && (!event.extendedProps.is_full || event.extendedProps.is_outdated);
        var isFull = event.extendedProps.is_full || event.extendedProps.is_outdated;
      
        if ((type == "all") ||
            (type == "registered" && isRegistered) ||
            (type == "available" && isAvailable) ||
            (type == "full" && isFull)) {
          event.setProp("display", "block");
        } else {
          event.setProp("display", "none");
        }
      });      
    });
  }

  formatDate(date, format = "dd-mm-yyyy") {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    if (format === "dd-mm-yyyy") {
      return `${day}-${month}-${year}`;
    }else{
      return `${year}-${month}-${day}`;
    }
  }

  getURLParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  onDataChanged(data) {
    
  }

  onDestroy() {
    if (this.calendar) {
      this.calendar.destroy();
      this.calendar = null;
    }
    $("#store-select").select2('destroy');
  }
}