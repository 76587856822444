import ApexCharts from 'apexcharts';

export default class StoreAdminDashboardVolatility {
  
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_sondage_chart();
    this.display_supermarket_chart();
    this.display_visited_stores_chart();
  }

  display_sondage_chart() {
    var data = $("#sondage_chart").data("sondage");

    var options = {
        series: [data, 100 - data],
        chart: {
          type: 'pie',
          offsetY: 20,
          height: 300,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: ['Oui', 'Non'],
      colors: ["#70a7dc", "#1f3864"],
    };

    var chart = new ApexCharts(document.querySelector("#sondage_chart"), options);
    chart.render();
  }

  display_supermarket_chart(){
    var data = $("#supermarkets_chart").data("supermarkets");

    var options = {
      series: [{
      name: 'Nombre de clients',
      data: data[1]
    }],
      chart: {
      type: 'bar',
      height: 300,
      offsetY: 20,
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: data[0]
    },
    fill: {
      opacity: 1
    },
    colors: ["#70a7dc"],
  };

    var chart = new ApexCharts(document.querySelector("#supermarkets_chart"), options);
    chart.render();
  }

  display_visited_stores_chart(){
    var data = $("#visited_stores_chart").data("visitedStores");
    var options = {
      series: [{
      name: 'Nombre de clients',
      data: data[1]
    }],
      chart: {
      type: 'bar',
      height: 300,
      offsetY: 20,
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: data[0]
    },
    yaxis: [
      {
        labels: {
          formatter: function(val) {
            return val.toFixed(0);
          }
        }
      }
    ],
    fill: {
      opacity: 1
    },
    colors: ["#70a7dc"],
  };

    var chart = new ApexCharts(document.querySelector("#visited_stores_chart"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $("#sondage_chart").empty();
    $("#supermarkets_chart").empty();
  }
}
