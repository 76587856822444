import ApexCharts from 'apexcharts';

export default class StoreAdminDashboardShow {
  
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_chart_recommandation();
    this.display_chart_rates();
    this.display_highlights_chart();
  }

  display_chart_recommandation() {
    var recommandation_percent = parseFloat($("#chart_recommandation").data("recommandation"));
    
    var options = {
      series: [recommandation_percent],
      chart: {
      height: "300px",
      type: 'radialBar',
      offsetY: -30,
      },
      plotOptions: {
        radialBar: {
          startAngle: -140,
          endAngle: 140,
          dataLabels: {
            name: {
              show: true,
              fontColor: '#3970C7',
              fill: '#3970C7',
              fontSize: '12px',
              fontWeight: 'light',
              offsetY: 26
            },
            value: {
              offsetY: -10,
              fontSize: '32px',
              fontWeight: 'bold',
              color: "#3970C7",
              formatter: function (val) {
                return val + "%" ;
              }
            }
          },
          track: {
            show: true,
          },
        }
      },
      labels: [""],
      colors: ["#3970C7"],
      legend: {
        show: false,
      },
    };


    const chart = new ApexCharts(document.querySelector("#chart_recommandation"), options);
    chart.render();
  }

  display_chart_rates() {
    var data = $("#chart_rates").data("series");

    var options = {
      series: [{
      name: 'Homme',
      data: data[0],
      color: "#70a7dc",
    }, {
      name: 'Femme',
      data: data[1],
      color: "#33C5D4",
    }, {
      name: 'Couple',
      data: data[2],
      color: "#3970C7",
    }],
      chart: {
      type: 'bar',
      height: 230,
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    },
    yaxis: {
      show: true,
    },
    fill: {
      opacity: 1
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    legend: {
      markers: {
        width: 10,
        height: 10,
        radius: 50,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0
      }
    }
  };

    var chart = new ApexCharts(document.querySelector("#chart_rates"), options);
    chart.render();
  }

  display_highlights_chart() {
    var data = $("#highlights_chart").data("highlights");
    var labels = $("#highlights_chart").data("labels");

    var options = {
        series: [{
        data: data,
      }],
        chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          barHeight: '80%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom'
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: labels,
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      colors: ["#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc", "#3970C7", "#33C5D4", "#70a7dc"],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff'],
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
        },
        offsetX: 0,
      },
    };

    var chart = new ApexCharts(document.querySelector("#highlights_chart"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $("#chart_rates").empty();
    $("#chart_recommandation").empty();
  }
}
