import AdminCalendar from "../utils/AdminCalendar";

export default class StoreAdminsEventsIndex {

    constructor() {
        this.adminCalendar = new AdminCalendar();
        this.bindEvents();
    }


    bindEvents() {

    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.adminCalendar.onDestroy();
    }
}