import MediaManager from "../utils/MediaManager";

export default class MediaIndex {

    constructor() {
        this.mediaManager = new MediaManager();
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.mediaManager.onDestroy();
    }

}