export default class MediaManager {

    constructor(container = document, scroll_container = $("body").get(0), template_type = "default_medium") {
        this.FILE_MAX_SIZE = 3072000;// <- 3Mb; 10 Mb: 10240000
        this.FILE_ALLOWED_TYPES = []; //["image/jpg", "image/jpeg", "image/png"];

        this.container = container;
        this.window = scroll_container;
        this.template_type = template_type

        this.counter_draggover = 0;
        this.page = 1;
        this.is_reset = true;
        this.timeout = null;

        this.currentSelected = [];
        this.selectedInput = null;
        this.selectedPreview = null;

        this.orderFilter = null;
        this.mediaTypeFilter = null;

        this.bindEvents();
        this.bindDroppableEvents();
        this.resetGrid();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        let self = this;
        // File Input Event
        $("#new_file").change(function () {self.createMedia(this.files);});

        // Search events
        $("#search_text").keyup(this.onSearchTextKeyup);
        $("#search_order").change(this.onSearchOrderChange);
        $("#search_media_type").change(this.onSearchMediaTypeChange);
        this.addSelectEvents();
        $("#select_media").click(this.setSelectedMedia)

        this.orderFilter = $('#search_order').selectpicker();
        this.mediaTypeFilter = $('#search_media_type').selectpicker();
    }

    bindDroppableEvents(){
        let self = this;
        // Document drop events
        $(self.container).bind(this.setDroppableEvents());
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            self.container.addEventListener(eventName, self.preventDefaults, false)
        });
        self.container.addEventListener('drop', self.handleDrop, false);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        console.log("onDestroy");
        // File Input Event
        $("#new_file").off("change");

        // Reset preview modal on close
        $("#media_preview").off("hidden.bs.modal", this.resetMediaModal)

        // Search events
        $("#search_text").off("keyup", this.onSearchTextKeyup);
        $("#search_order").off("change", this.onSearchOrderChange);

        // Remove Scroll Listener
        $(this.container).unbind('scroll', this.onScroll);

        this.orderFilter.selectpicker('destroy');
        this.mediaTypeFilter.selectpicker('destroy');
    }

    resetSelectEvents(){
        this.removeSelectEvents();
        this.addSelectEvents();
    }

    addSelectEvents(){
        $(".js_single_image").click(this.getSingleImage)
    }

    removeSelectEvents(){
        $(".js_single_image").off("click", this.getSingleImage);
    }

    addGridEvents(){

        // Edit and Destroy
        $(".js-edit-medium").click(this.openEdit);
        $(".js-destroy-medium").click(this.confirmDestroy);
    }

    removeGridEvents(){

        // Reset preview modal on close
        $("#media_preview").off("hidden.bs.modal", this.resetMediaModal)

        // Edit and Destroy
        $(".js-edit-medium").off("click", this.openEdit);
        $(".js-destroy-medium").off("click", this.confirmDestroy);
    }

    resetEvents(){
        this.removeGridEvents();
        this.addGridEvents();
        this.initPreviewPopup();
    }


    /** ----------- START Preview Modal -----------  **/
    initPreviewPopup(){
        $(".preview-popup").off("click", this.setPreviewEvents).click(this.setPreviewEvents)
    }

    setPreviewEvents(e){
        let self = application.current_handler.mediaManager;
        self.resetMediaModal();
        e.preventDefault();
        let $el = $(this)
        let type = $el.data("type");
        switch (type) {
            case 'image':
                self.setPreviewImage($el);
                break;
            case 'video':
                self.setPreviewVideo($el);
                break;
            case 'audio':
                self.setPreviewAudio($el);
                break;
            case 'pdf':
                self.setPreviewPdf($el);
                break;
            default:
                self.setPreviewDefault($el);
        }
    }

    setPreviewImage($el){
        let $imagePreview = $("#image_preview");
        let $img = $imagePreview.find("img");

        $imagePreview.removeClass("d-none");

        $img.attr("src", $el.attr("href"));

        this.showMediaPreviewModal($el.data("title"));
    }

    setPreviewVideo($el){
        let $videoPreview = $("#video_preview")
        let video = $videoPreview.find("video").get(0);
        let $source = $videoPreview.find("source");

        $videoPreview.removeClass("d-none");

        $source.attr("src", $el.attr("href"));
        $source.attr("type", $el.data("source-type"));

        video.load();
        this.showMediaPreviewModal($el.data("title"));
    }

    setPreviewAudio($el){
        let $audioPreview = $("#audio_preview")
        let audio = $audioPreview.find("audio").get(0);
        let $source = $audioPreview.find("source");

        $audioPreview.removeClass("d-none");

        $source.attr("src", $el.attr("href"));
        $source.attr("type", $el.data("source-type"));

        audio.load();
        this.showMediaPreviewModal($el.data("title"));
    }

    setPreviewPdf($el){
        let $pdfPreview = $("#pdf_preview");
        let $iframe = $pdfPreview.find("iframe");

        $pdfPreview.removeClass("d-none");

        $iframe.attr("src", $el.attr("href"));

        this.showMediaPreviewModal($el.data("title"));
    }

    setPreviewDefault($el){
        let $defaultPreview = $("#default_preview");
        let $link = $defaultPreview.find("a");

        $defaultPreview.removeClass("d-none");

        $link.attr("href", $el.attr("href"));

        this.showMediaPreviewModal($el.data("title"));
    }

    showMediaPreviewModal(title = ""){
        $("#media_preview_title").text(title);
        $("#media_preview").modal("show");
    }

    resetMediaModal(e){
        $("#image_preview").addClass("d-none");
        $("#video_preview").addClass("d-none");
        $("#audio_preview").addClass("d-none");
        $("#pdf_preview").addClass("d-none");
        $("#default_preview").addClass("d-none");
    }
    /** ----------- END Preview Modal -----------  **/


    /** ----------- START Handle Files -----------  **/
    makeId(length) {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    setDroppableEvents(){
        let self = this;
        let $droppable = $("#droppable");

        return {
            dragenter: function(ev) {
                ev.preventDefault(); // needed for IE
                self.counter_draggover++;
                $droppable.addClass('show');
                $(self.window).css("overflow-y", "hidden");
                setTimeout(function() {
                    $droppable.addClass('display');
                }, 50);
            },
            dragleave: function() {
                self.counter_draggover--;
                if (self.counter_draggover === 0) {
                    $droppable.removeClass('display');
                    $(self.window).css("overflow-y", "auto");
                    setTimeout(function() {
                        $("#droppable").removeClass('show');
                    }, 200);
                }
            },
            drop: function (){
                $droppable.removeClass('display');
                $(self.window).css("overflow-y", "auto");
                setTimeout(function() {
                    $droppable.removeClass('show');
                }, 200);
                self.counter_draggover = 0;
            }
        }
    }

    preventDefaults (e) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop(e) {
        let self = application.current_handler.mediaManager;
        let dt = e.dataTransfer;
        let files = dt.files;
        self.createMedia(files);
    }

    validateFiles(files){
        let self = application.current_handler.mediaManager
        let valid_files = []
        let msg = ""

        $.each(files,function(idx,elm){
            if(elm.size < self.FILE_MAX_SIZE){//} && self.FILE_ALLOWED_TYPES.includes(elm.type)){
                valid_files.push(elm);
            }else{
                msg += I18n.t("backoffice.media.media_manager.error_li_html", {errors: elm.name})
            }
        })

        if(msg !== ""){
            application.errorNotification(I18n.t("backoffice.media.media_manager.error_ul_html", {errors: msg}))
        }

        return valid_files
    }
    /** ----------- END Handle Files -----------  **/

    /** ----------- START Ajax Create -----------  **/
    createMedia(files) {
        let self = this;

        let valid_files = this.validateFiles(files)
        $('#new_file').val("");

        $.each(valid_files,function(idx,elm){
            let code_name = self.makeId(5);

            // TODO: Add a loading element on the grid

            let formData = new FormData();
            formData.append('medium[medium]', elm);
            formData.append('medium[title]', elm.name.split('.')[0]);
            formData.append('code_name', code_name);

            $.ajax({
                url : '/media/ajax_create',
                type : 'POST',
                data : formData,
                processData: false,
                contentType: false,
                success : function(html) {
                    // TODO: Replace the loading element
                    //$("#media_container").prepend(html);
                    application.successNotification(I18n.t("backoffice.media.media_manager.success_create"))
                    self.resetGrid();
                },
                xhr: function () {
                    let myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', function (e) {
                            // TODO: Update the loading element progress bar
                        }, false);
                    }
                    return myXhr;
                },
                error : function (data) {
                }
            });

        });
    }
    /** ----------- END Ajax Create -----------  **/


    /** ----------- START Ajax Search -----------  **/
    onSearchTextKeyup(e){
        let self = application.current_handler.mediaManager;
        clearTimeout(self.timeout);
        self.timeout = setTimeout(function (){
            self.resetGrid();
        }, 500)
    }

    onSearchOrderChange(e){
        let self = application.current_handler.mediaManager;
        self.resetGrid();
    }

    onSearchMediaTypeChange(e){
        let self = application.current_handler.mediaManager;
        self.resetGrid();
    }
    /** ----------- START Ajax Search -----------  **/


    /** ----------- START Ajax Index Grid -----------  **/
    resetGrid(){
        this.is_reset = true
        this.page = 1;
        this.ajax_render_grid();
    }

    ajax_render_grid(){
        $(this.container).unbind('scroll', this.onScroll);

        $.ajax({
            url: "/media/ajax_index",
            data: this.ajaxIndexData(),
            success: this.ajaxIndexSuccess,
            error: this.ajaxIndexError,
        });
    }

    ajaxIndexData(){
        return {
            search: $("#search_text").val(),
            page: this.page,
            selected_media: this.currentSelected,
            media_type: $("#search_media_type").val(),
            template_type: this.template_type,
            order_field: $("#search_order option:selected").data("field"),
            order_dir: $("#search_order option:selected").data("order")
        }
    }

    ajaxIndexSuccess(html){
        let self = application.current_handler.mediaManager;
        let $container = $("#media_container");
        if(self.is_reset){
            self.is_reset = false
            $container.html("");
        }

        let before = $("[id^=medium_]").length;
        $container.append(html);
        let after = $("[id^=medium_]").length;


        if(before !== after){ $(self.container).on('scroll', self.onScroll); } // Scroll event

        self.page++;
        self.resetEvents();
    }

    ajaxIndexError(data){
        console.log(data);
    }
    /** ----------- END Ajax Index Grid -----------  **/


    /** ----------- START Ajax Edit -----------  **/
    openEdit(e){
        e.preventDefault();
        let self = application.current_handler.mediaManager;

        self.completeMediaModal($(this).attr("href"));
    }

    setEditValidator(){
        let self = this;
        $("#media_form").submit(self.submitEdit);
        application.setTemplateValidation(MediaManager.validationRules(), "#media_form");
    }

    submitEdit(e){
        e.preventDefault();
        let self = application.current_handler.mediaManager;
        let $el = $("#media_form");

        if($el.valid()){
            $.ajax({
                url: $el.attr("action"),
                method: "PUT",
                data: {
                    template_type: self.template_type,
                    "medium[title]": $("#medium_title").val()
                },
                success: self.ajaxUpdateSuccess,
                error: self.ajaxUpdateError,
            });
        }
    }

    ajaxUpdateSuccess(html){
        let self = application.current_handler.mediaManager;
        let id = $(html).data("id");
        $(`#medium_${id}`).replaceWith(html);
        self.hideMediaModal();
        self.resetEvents();
        application.successNotification(I18n.t("backoffice.media.media_manager.success_update"))
    }

    ajaxUpdateError(data){
        application.errorNotification(I18n.t("backoffice.media.media_manager.error_update"))
    }

    static validationRules(){
        return {
            "medium[title]": {
                required: true
            }
        }
    }

    resetValidator(){
        if( $("#media_form").length > 0 ){
            application.templateValidation.onDestroy();
            application.templateValidation = null;
        }
    }
    /** ----------- END Ajax Edit -----------  **/


    /** ----------- START Ajax Delete -----------  **/
    confirmDestroy(e){
        e.preventDefault();
        let self = application.current_handler.mediaManager;
        self.completeMediaModal($(this).attr("href"));
    }

    setDestroyEvent(){
        $(".js-destroy-medium-confirmed").click(this.destroyMedium)
    }

    destroyMedium(e){
        e.preventDefault();
        let self = application.current_handler.mediaManager;

        $.ajax({
            url: $(this).data("url"),
            method: "DELETE",
            success: self.ajaxDestroySuccess,
            error: self.ajaxDestroyError,
        });
    }

    ajaxDestroySuccess(data){
        let self = application.current_handler.mediaManager;
        self.hideMediaModal();
        application.successNotification(I18n.t("backoffice.media.media_manager.success_destroy"));
        // TODO: Fade animation to remove
        $(`#medium_${data.id}`).remove();
    }

    ajaxDestroyError(data){
        application.successNotification(I18n.t("backoffice.media.media_manager.error_destroy"))
    }
    /** ----------- END Ajax Delete -----------  **/


    /** ----------- START Scroll -----------  **/
    onScroll(e){
        application.current_handler.mediaManager.load_page_if_visible()
    }

    load_page_if_visible(){
        let $el = $(".js-reload");
        let $container = $(this.window);

        let top_of_element = $el.offset().top;
        let bottom_of_element = $el.offset().top + $el.outerHeight();
        let bottom_of_screen = $container.scrollTop() + $container.innerHeight();
        let top_of_screen = $container.scrollTop();

        if ( (bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element) ) {
            application.current_handler.mediaManager.ajax_render_grid();
        }
    }
    /** ----------- END Scroll -----------  **/


    /** ----------- START Utility -----------  **/
    showMediaModal(){
        $("#media_modal").modal("show");
    }
    hideMediaModal(){
        $("#media_modal").modal("hide");
    }

    completeMediaModal(url){
        let self = this;
        self.resetValidator();

        $.ajax({
            url: url,
            success: function (html){
                $("#media_modal_content").empty().append(html)
                self.showMediaModal();
            }
        })
    }
    /** ----------- END Utility -----------  **/


    /** ----------- START Pickers -----------  **/
    getSingleImage(e){


        let $el = $(this);
        let self = application.current_handler.mediaManager;
        self.template_type = "radio_medium";

        self.selectedInput = $el.data("input");
        self.selectedPreview = $el.data("preview");

        self.currentSelected = $(self.selectedInput).val() === "" ? [] : [$(self.selectedInput).val()]

        self.resetGrid();
        $("#media_picker").modal("show");
    }

    setSelectedMedia(e){
        let self = application.current_handler.mediaManager
        if(self.template_type === "radio_medium"){
            let $checked = $("[name=medium_selection_radio]:checked")
            if($checked.length > 0){
                $(self.selectedInput).val($checked.val()).trigger('change')
                $(self.selectedPreview).attr("src", $checked.data("url"))
                $(self.selectedPreview).removeClass("d-none")
                $(self.selectedPreview + " + div").addClass("d-none")
            }
        }
        $("#media_picker").modal("hide");
    }
    /** ----------- END Pickers -----------  **/

}