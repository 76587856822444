import CompanionForm from "../controllers/CompanionForm";

export default class CompanionsProfileIndex {

  constructor() {
    this.form = new CompanionForm();
    this.bindEvents();
  }

  bindEvents() {
  }

  onDataChanged(data) {
  }

  onDestroy() {
    this.form.onDestroy();
  }
}