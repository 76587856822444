import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";
import MediaIndex from "../controllers/MediaIndex";
import CompanionForm from "../controllers/CompanionForm";
import DistributorForm from "../controllers/DistributorForm";
import CompanionIndex from "../controllers/CompanionIndex";
import DistributorIndex from "../controllers/DistributorIndex";
import StoreIndex from "../controllers/StoreIndex";
import StoreAdminDashboardShow from "../controllers/StoreAdminDashboardShow";
import StoreAdminDashboardRates from "../controllers/StoreAdminDashboardRates";
import StoreAdminsDashboardTypology from "../controllers/StoreAdminDashboardTypology";
import StoreAdminDashboardVolatility from "../controllers/StoreAdminsDashboardVolatility";
import StoreAdminDashboardAppInfos from "../controllers/StoreAdminsDashboardAppInfos";
import StoreAdminDashboardCartAnalysis from "../controllers/StoreAdminsDashboardCartAnalysis";
import CompanionsDashboardForm from "../controllers/CompanionsDashboardForm";
import StoreAdminsEventsIndex from "../controllers/StoreAdminsEventsIndex";
import BackofficeEventsIndex from "../controllers/BackofficeEventsIndex";
import CompanionsDashboardCalendar from "../controllers/CompanionsDashboardCalendar";
import BackofficeInvoicesIndex from "../controllers/BackofficeInvoicesIndex";
import CompanionsProfileIndex from "../controllers/CompanionsProfileIndex";
import CompanionsDashboardInscriptions from "../controllers/CompanionsDashboardInscriptions";

export default class Routes extends ConfigRoutes{

    static routes = {
        "BackofficeDashboardIndex": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        "BackofficeAdminMenusIndex": AdminMenuIndex,
        "BackofficeAdminMenusNew": AdminMenuForm,
        "BackofficeAdminMenusCreate": AdminMenuForm,
        "BackofficeAdminMenusEdit": AdminMenuForm,
        "BackofficeAdminMenusUpdate": AdminMenuForm,
        "BackofficeMediaIndex": MediaIndex,
        "BackofficeDistributorsNew": DistributorForm,
        "BackofficeDistributorsEdit": DistributorForm,
        "BackofficeCompanionsNew": CompanionForm,
        "BackofficeCompanionsCreate": CompanionForm,
        "BackofficeCompanionsEdit": CompanionForm,
        "BackofficeCompanionsIndex": CompanionIndex,
        "BackofficeDistributorsIndex": DistributorIndex,
        "BackofficeStoresIndex": StoreIndex,
        "CompanionsProfileIndex": ProfileIndex,
        "StoreAdminsDashboardShow": StoreAdminDashboardShow,
        "StoreAdminsDashboardRates": StoreAdminDashboardRates,
        "StoreAdminsDashboardTypology": StoreAdminsDashboardTypology,
        "StoreAdminsDashboardVolatility": StoreAdminDashboardVolatility,
        "StoreAdminsDashboardAppInfos": StoreAdminDashboardAppInfos,
        "BackofficeReportingShow": StoreAdminDashboardShow,
        "BackofficeReportingRates": StoreAdminDashboardRates,
        "BackofficeReportingTypology": StoreAdminsDashboardTypology,
        "BackofficeReportingVolatility": StoreAdminDashboardVolatility,
        "BackofficeReportingAppInfos": StoreAdminDashboardAppInfos,
        "StoreAdminsDashboardCartAnalysis": StoreAdminDashboardCartAnalysis,
        "BackofficeReportingCartAnalysis": StoreAdminDashboardCartAnalysis,
        "CompanionsDashboardForm": CompanionsDashboardForm,
        "StoreAdminsEventsIndex": StoreAdminsEventsIndex,
        "BackofficeEventsIndex": BackofficeEventsIndex,
        "CompanionsDashboardCalendar": CompanionsDashboardCalendar,
        "BackofficeInvoicesIndex": BackofficeInvoicesIndex,
        "CompanionsProfileIndex": CompanionsProfileIndex,
        "CompanionsDashboardInscriptions": CompanionsDashboardInscriptions
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}