import AdminCalendar from "../utils/AdminCalendar";

export default class BackofficeEventsIndex {

    constructor() {
        this.adminCalendar = new AdminCalendar();
        this.bindEvents();
    }


    bindEvents() {
      var self = this;

      $("#store-select, #store-select-e").select2({
        placeholder: "Sélectionnez un magasin",
      });

      $("#zone-select, #zone-select-e").select2({
        placeholder: "Sélectionnez une zone",
        allowClear: true
      });

      $(".select2-selection__arrow").addClass("d-flex align-items-center").html('<i class="fas fa-chevron-down fs-8"></i>');

      $("#zone-select").on("change", function () {
        const zoneId = $(this).val();

        self.adminCalendar.calendar.getEvents().forEach((event) => {
          if (event.extendedProps.zone_id == zoneId) {
            event.setProp("display", "block");
          } else {
            event.setProp("display", "none");
          }
        });
        $(".select2-selection__clear").addClass('d-none');
      });

      $(".select2-selection__clear").addClass('d-none');
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.adminCalendar.onDestroy();
        $("#zone-select, #zone-select-e").select2('destroy');
        $("#store-select, #store-select-e").select2('destroy');
    }
}