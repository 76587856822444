export default class CompanionForm {

  constructor() {
      this.dropify = null;
      this.role_select = null;
      this.bindEvents();
      //window.application.setOnDataChangeListener(this);
  }


  bindEvents() {
      this.dropify = $('#profile_picture').dropify({
          messages: {
              'default': "",
              'replace': I18n.t('plugins.dropify.messages.replace'),
              'remove':  I18n.t('plugins.dropify.messages.remove'),
              'error':   I18n.t('plugins.dropify.messages.error')
          },
          error: {
              'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
              'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
              'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
              'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
              'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
              'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
              'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
          }});

      this.role_select = $('#admin_role_id').selectpicker();

      $("#zone-select").select2({
          placeholder: "Séléctionner une plusieurs zones",
      });
  }

  onDataChanged(data) {
  }

  onDestroy() {
      this.role_select.selectpicker('destroy');
      this.dropify.data('dropify').destroy();
      $("#zone-select").select2('destroy');
  }

}