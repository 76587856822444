export default class DistributorForm {

  constructor() {
      this.dropify = null;
      this.role_select = null;
      this.bindEvents();
      //window.application.setOnDataChangeListener(this);
  }


  bindEvents() {
        this.dropify1 = $('#distributor_image').dropify({
          messages: {
              'default': "Ajouter l'image promotionnelle de l'enseigne",
              'replace': I18n.t('plugins.dropify.messages.replace'),
              'remove':  I18n.t('plugins.dropify.messages.remove'),
              'error':   I18n.t('plugins.dropify.messages.error')
          },
          error: {
              'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
              'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
              'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
              'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
              'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
              'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
              'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
          }});

      this.dropify2 = $('#promotion_image').dropify({
          messages: {
              'default': "Ajouter l'image promotionnelle de l'enseigne",
              'replace': I18n.t('plugins.dropify.messages.replace'),
              'remove':  I18n.t('plugins.dropify.messages.remove'),
              'error':   I18n.t('plugins.dropify.messages.error')
          },
          error: {
              'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
              'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
              'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
              'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
              'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
              'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
              'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
          }});

      this.role_select = $('#admin_role_id').selectpicker();
      // uncheck has_promotions
      $("#has_application").prop('checked', false);
      $("#has_promotions").prop('checked', false);
      $("#app_fields").hide();
      $("#promotion_fields").hide();
      
      if ($("#has_application").data("app") == true) {
        $("#has_application").prop('checked', true);
        $("#app_fields").show();
      }

      if ($("#has_promotions").data("promotions") == true) {
        $("#has_promotions").prop('checked', true);
        $("#promotion_fields").show();
      }

      $("#has_application").on('change', function() {
          if ($(this).is(':checked')) {
              $("#app_fields").show();
          } else {
              $("#app_fields").hide();
          }
      });

      $("#has_promotions").on('change', function() {
          if ($(this).is(':checked')) {
              $("#promotion_fields").show();
          } else {
              $("#promotion_fields").hide();
          }
      });
  }

  onDataChanged(data) {
  }

  onDestroy() {
      this.role_select.selectpicker('destroy');
      this.dropify1.data('dropify').destroy();
      this.dropify2.data('dropify').destroy();
  }

}