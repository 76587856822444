import ApexCharts from 'apexcharts';
import { parseHTML } from 'jquery';

export default class StoreAdminDashboardShow {
  
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_chart_rate();
    this.display_chart_recommandation();
  }

  display_chart_rate() {
    var rate = parseFloat($("#chart_rate").data("rate"));

    var options = {
      series: [rate],
      chart: {
      height: "210px",
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -140,
        endAngle: 140,
        dataLabels: {
          name: {
            show: true,
            fontColor: '#4FADEA',
            fill: '#4FADEA',
            fontSize: '12px',
            fontWeight: 'light',
            offsetY: 20,
          },
          value: {
            offsetY: 40,
            offsetY: -10,
            fontSize: '26px',
            fontWeight: 'bold', 
            formatter: function (val) {
              return val/10
            }          
          }
        },
        track: {
          show: true,
        },
      }
    },
    labels: ["/10"],
    colors: ["#4FADEA"],
    legend: {
      show: false,
    },
  };

  const chart = new ApexCharts(document.querySelector("#chart_rate"), options);
  chart.render();
}

  display_chart_recommandation() {
    var recommandation_percent = parseFloat($("#chart_recommandation").data("recommandation"));
    
    var options = {
      series: [recommandation_percent],
      chart: {
      height: "210px",
      type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: -140,
          endAngle: 140,
          dataLabels: {
            name: {
              show: true,
              fontColor: '#3970C7',
              fill: '#3970C7',
              fontSize: '12px',
              fontWeight: 'light',
            },
            value: {
              offsetY: -10,
              fontSize: '26px',
              fontWeight: 'bold',
              color: "#3970C7",
              formatter: function (val) {
                return val + "%" ;
              }
            }
          },
          track: {
            show: true,
          },
        }
      },
      labels: [""],
      colors: ["#3970C7"],
      legend: {
        show: false,
      },
    };


    const chart = new ApexCharts(document.querySelector("#chart_recommandation"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {

  }
}
