export default class CompanionsDashboardInscriptions {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    const urlParams = new URLSearchParams(window.location.search);
    const launchRules = urlParams.get('launch_rules');

    if (launchRules) {
      $('#rules-modal').modal({
        backdrop: 'static',
        keyboard: false
      }, 'show');
    }

    $("#close-modal").on("click", () => {
      if ($('#accept-rules').is(':checked')) {
        $('#rules-modal').modal('hide');
      }else{
        $("#accept-rules-label").removeClass("text-primary").addClass("text-danger");
      }
    });

    let secondsLeft = 4;
    const timerInterval = setInterval(() => {
      if (secondsLeft === 0) {
        clearInterval(timerInterval);
        $("#close-modal").prop("disabled", false).text("Fermer");
      } else {
        $("#close-modal").prop("disabled", true).text(`Fermer (${secondsLeft} secondes)`);
        secondsLeft--;
      }
    }, 1000);
  }

  onDataChanged(data) {
  }

  onDestroy() {
  }
}