import ApexCharts from 'apexcharts';

export default class StoreAdminDashboardCartAnalysis {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    this.display_cart_analysis_chart();
    this.display_ez_shopping_chart();
  }

  display_cart_analysis_chart() {
    var data = $("#cart-chart").data("series");
    var labels = $("#cart-chart").data("labels");

    var options = {
    series: data,
     chart: {
        type: 'pie',
        offsetY: 20,
        height: 400,
      },
    labels: labels,
    colors: ["#A5A5A5", "#01B0F0", "#5A9BD5", "#1F3864"],
    legend: {
        position: 'bottom',
        fontSize: '14px',
      },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          height: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
    };

    var chart = new ApexCharts(document.querySelector("#cart-chart"), options);
    chart.render();
  }

  display_ez_shopping_chart() {
    var data = $("#ez_shopping_chart").data("series");

    var options = {
        series: [data, 100 - data],
        chart: {
          type: 'pie',
          offsetY: 20,
          height: 300,
        },
      legend: {
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }],
      labels: ['Oui', 'Non'],
      colors: ["#70a7dc", "#1f3864"]
    };

    var chart = new ApexCharts(document.querySelector("#ez_shopping_chart"), options);
    chart.render();
  }

  onDataChanged(data) {

  }

  onDestroy() {
    $("#cart-chart").empty();
  }
}