
export default class PermissionsIndex {

    constructor() {
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {
        $(".js-permission-switch").unbind("change").change(this.update_is_only_super);
        $(".js-permission-switch-available-for-all").unbind("change").change(this.update_is_available_for_all);
        $(".js-permission-send").unbind("click").click(this.update_action_as);
    }

    onDataChanged(data) {
    }

    onDestroy() {
        $(".permission_switch").unbind("change");
    }

    update_is_only_super(){
        let self = application.current_handler
        self.update_permission($(this).data("id"), 'is_only_super', $(this).is(':checked'))
    }

    update_is_available_for_all(){
        let self = application.current_handler
        self.update_permission($(this).data("id"), 'is_available_for_all', $(this).is(':checked'))
    }

    update_action_as(){
        let self = application.current_handler
        let id = $(this).data("id");

        self.update_permission(id, 'action_as', $("#permission_action_as_"+id).val())
    }

    update_permission(id, file_name, value){
        let data = {};
        let field_value = {};
        field_value[file_name] = value
        data['permission'] = field_value

        let ajax_data = {
            url: "permissions/"+id+"/ajax_update",
            data: data,
            method: "put",
            success: function (data){
                application.successNotification(I18n.t("backoffice.permissions.saved"))
            },
            error: function (err){
                application.errorNotification(I18n.t("backoffice.permissions.error_saved"))
            }
        }

        $.ajax(ajax_data)
    }
}