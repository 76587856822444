import { t } from "i18n-js";

export default class CompanionIndex {

  constructor() {
    this.bindEvents();
  }


  bindEvents() {
    this.dataTable = $('.companions-table').DataTable({
      "info": false,
      retrieve: true,
      "lengthChange": false,
      "searching": true,
      "language": {
          url: '/assets/data-tables/locales/' + I18n.locale + '.json',
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
      },
      dom: 'Bfrtip',
      paging: true,
      pageLength: 50,
      buttons: [],
      "columnDefs": [
        {
          "targets": [ 0, 6],
          "orderable": false,
        }]
    });

    this.dataTable.on('draw', () => {
      if ($(".placed").length == 0) {
        $("#seach-bar-wrapper").html($(".form-control-sm"));
        $(".form-control-sm").attr("placeholder", "Rechercher...");
        $(".form-control-sm").addClass("placed").removeClass("form-control-sm");
        $("#DataTables_Table_0_filter").addClass("d-none");
      }

      if($('.dataTables_filter').length >= 0) {
        $('.dataTables_filter').addClass('d-none'); 
      }
    });
  }

  onDataChanged(data) {
    
  }

  onDestroy() {
   if (this.dataTable) {
     this.dataTable.destroy();
      this.dataTable = null;
   }
  }
}